import Content from "components/Content/Content";
import DetailJob from "pages/DetailJob/DetailJob";
import Jobs from "pages/Jobs/Jobs";
import NotFound from "pages/NotFound/NotFound";

export const mainRoutes = [
  {
    path: "/",
    component: Content,
    isPublic: true,
  },
  {
    path: "job",
    component: Jobs,
    isPublic: true,
  },
  {
    path: "detail-job/:id",
    component: DetailJob,
    isPublic: true,
  },
  {
    path: "not-found",
    component: NotFound,
    isPublic: true
  }
];
