import classNames from "classnames";
import { useEffect, useState } from "react";
import "./Sec2.scss";

function Sec2({ scrollPosition }) {
  const [animateFirst, setAnimateFirst] = useState(false);
  const [animateSecond, setAnimateSecond] = useState(false);
  const [animateThird, setAnimateThird] = useState(false);
  const [animateFourth, setAnimateFourth] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 800) {
      if (scrollPosition >= 600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1000) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 1300) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 1700) {
        setAnimateFourth(true);
      }
    } else if (window.innerWidth <= 800 && window.innerWidth > 767) {
      if (scrollPosition >= 1200) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1700) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 2200) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 2700) {
        setAnimateFourth(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 1600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 2000) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 2600) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 3000) {
        setAnimateFourth(true);
      }
    } else {
      if (scrollPosition >= 800) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1300) {
        setAnimateSecond(true);
      }
      if (scrollPosition >=1700) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 2100) {
        setAnimateFourth(true);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="sec2">
      <div
        className={classNames("sec2-item1", {
          animate: animateFirst,
        })}
      >
        <div className="left-item">
          <div className="icon-service">
            <img src={require("assets/images/icons/web-icon.png")} alt="" />
          </div>
          <div className="content-sec2">
            <h1>Web Development</h1>
            <p>
              We carry more than just good coding skills. Our experience makes
              us stand out from other web development.
            </p>
          </div>
        </div>
        <div className="right-item">
          <img
            src={require("assets/images/icons/web-img.png")}
            alt=""
            className="icon-service"
          />
        </div>
      </div>
      <div
        className={classNames("sec2-item2", {
          animate: animateSecond,
        })}
      >
        <div className="left-item">
          <img
            src={require("assets/images/icons/mobile-img.png")}
            alt=""
            className="icon-service"
          />
        </div>
        <div className="right-item">
          <div className="icon-service">
            <img src={require("assets/images/icons/mobile-icon.png")} alt="" />
          </div>
          <div className="content-sec2">
            <h1>Mobile Development</h1>
            <p>
              Create complex enterprise software, ensure reliable software
              integration, modernise your legacy system.
            </p>
          </div>
        </div>
      </div>
      <div
        className={classNames("sec2-item3", {
          animate: animateThird,
        })}
      >
        <div className="left-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/software-icon.png")}
              alt=""
            />
          </div>
          <div className="content-sec2">
            <h1>Software Development</h1>
            <p>
              We transform businesses of most major sectors with powerful and
              adaptable digital solutions that satisfy the needs of today.
            </p>
          </div>
        </div>
        <div className="right-item">
          <img
            src={require("assets/images/icons/software-img.png")}
            alt=""
            className="icon-service"
          />
        </div>
      </div>
      <div
        className={classNames("sec2-item4", {
          animate: animateFourth,
        })}
      >
        <div className="left-item">
          <img
            src={require("assets/images/icons/cpu.png")}
            alt=""
            className="icon-service"
          />
        </div>
        <div className="right-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/outsource-icon.png")}
              alt=""
            />
          </div>
          <div className="content-sec2">
            <h1>Outsourcing</h1>
            <p>
              We help businesses elevate their value through custom software
              development, product design, QA and consultancy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec2;
