import Header from "components/Content/components/Header/Header";
import { useEffect } from "react";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import Service from "./components/Service/Service";
import "./Content.scss";

function Content({ menuSelect, scrollPosition, onSelectMenu, delayAnimation }) {
  useEffect(() => {
    const menuActiveEl = document.getElementById(`${menuSelect}`);
    if (menuActiveEl) {
      menuActiveEl.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuSelect]);

  return (
    <div className="content">
      <Header
        idElement="home"
        scrollPosition={scrollPosition}
        menuActive={menuSelect}
        onSelectMenu={onSelectMenu}
        delayAnimation={delayAnimation}
      />
      <Service scrollPosition={scrollPosition} idElement="service" />
      <AboutUs idElement="about" scrollPosition={scrollPosition} />
      <Contact idElement="contact" scrollPosition={scrollPosition} />
    </div>
  );
}

export default Content;
