import Sec1 from "./Sec1/Sec1";
import Sec2 from "./Sec2/Sec2";
import Sec3 from "./Sec3/Sec3";
import "./Service.scss";

function Service({ idElement, scrollPosition }) {
  return (
    <div className="service" id={idElement}>
      <div className="service-wrapper">
        <Sec1 scrollPosition={scrollPosition} />
        <Sec2 scrollPosition={scrollPosition} />
        <Sec3 scrollPosition={scrollPosition} />
      </div>
    </div>
  );
}

export default Service;
