import "./NotFound.scss";

function NotFound() {
    return ( 
        <div className="not-found-page">
            <div className="img-wrapper">
                <img src={require("assets/images/icons/404.png")} className="img-404" alt="" />
            </div>
        </div>
     );
}

export default NotFound;