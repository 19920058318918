import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./DetailJob.scss";
import { Administrator, BackEnd, Design, FrontEnd, Tester } from "./JobData";
import { createRef, useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Popup from "components/Popup/Popup";
import PopupSubmitSuccess from "components/Popup/PopupSubmitSuccess/PopupSubmitSuccess";
import ReCAPTCHA from "react-google-recaptcha";

function DetailJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const form = createRef();
  const recaptchaRef = useRef();

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [isError, setIsError] = useState(0);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isContent, setIsContent] = useState(false);

  useEffect(() => {
    if (Number(id) <= 5) {
      setIsContent(true);
    } else {
      navigate("/not-found");
    }
  }, [id]);

  const handleBackPage = () => {
    navigate("/job");
  };

  const changeFile = (e) => {
    setIsError(0);
    setFile(e.value.slice(12));

    uploadImage(e.files[0]);
  };

  const handleChangeRecaptchaValue = (value) => {
    setIsRecaptchaVerified(true);
    setRecaptchaValue(value);
  };

  const uploadImage = (file) => {
    const formData = new FormData();

    if (file.size > 1024 * 50) {
      setIsError(4);
      setFile(null);
      return;
    }

    formData.append("file", file);

    fetch("https://file.io/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        setIsError(0);
        setFileUrl(result.link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sendEmail = (event) => {
    event.preventDefault();

    const name = event.target.user_name.value;
    const phone = event.target.user_phone.value;
    const email = event.target.user_email.value;
    const message = event.target.message.value;

    const recaptchaToken = recaptchaValue;

    if (!name.trim()) {
      setIsError(1);
      return;
    }
    if (!phone.trim()) {
      setIsError(2);
      return;
    }
    if (!email.trim()) {
      setIsError(3);
      return;
    }
    if (!fileUrl || !file) {
      setIsError(4);
      return;
    }

    const serviceId = "service_zfv2o7r";
    const templateId = "template_p5ktlfp";
    const userId = "hzHx0pDVjpp5Eq4-v";

    const emailParams = {
      from_name: "HR Eastar technology",
      user_name: name,
      user_email: email,
      user_phone: phone,
      message: message,
      file_url: fileUrl,
      file_name: file,
      "g-recaptcha-response": recaptchaToken,
    };

    if (isRecaptchaVerified) {
      emailjs.send(serviceId, templateId, emailParams, userId).then(
        (result) => {
          setIsError(0);
          setShowPopup(true);
          setIsRecaptchaVerified(false);
          recaptchaRef.current.reset();
          setRecaptchaValue(null);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      setFile(null);
      event.target.reset();
    } else {
      return;
    }
  };

  return (
    isContent && (
      <div className="detail-jobs">
        <div className="detail-wrapper">
          <div className="top-detail">
            <div className="back-page" onClick={handleBackPage}>
              <img
                src={require("assets/images/icons/back.png")}
                className="icon-back"
                alt=""
              />
              <div className="text-back">
                Back to <b>Jobs Listing</b>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="content-detail-wrapper">
            {Number(id) === 1 && <Administrator />}
            {Number(id) === 2 && <FrontEnd />}
            {Number(id) === 3 && <BackEnd />}
            {Number(id) === 4 && <Tester />}
            {Number(id) === 5 && <Design />}
            <div className="apply-job">
              <div className="title-form">
                <b>Let's join</b> the development together
              </div>
              <form
                encType="multipart/form-data"
                className="form-contact"
                ref={form}
                onSubmit={sendEmail}
              >
                <div className="info-user">
                  <div className="input-name">
                    <input
                      type="text"
                      className="input-item"
                      placeholder="Enter your name"
                      name="user_name"
                    />
                    {isError === 1 && <span className="error">*Requied</span>}
                  </div>
                  <div className="input-phone">
                    <input
                      type="number"
                      className="input-item"
                      placeholder="Enter your phone number"
                      name="user_phone"
                    />
                    {isError === 2 && <span className="error">*Requied</span>}
                  </div>
                </div>
                <div className="input-email">
                  <input
                    type="email"
                    className="input-item"
                    placeholder="Enter your email"
                    name="user_email"
                  />
                  {isError === 3 && <span className="error">*Requied</span>}
                </div>
                <div className="upload-file">
                  <div className="title-input-upload">
                    <div className="cate-upload">
                      CV{" "}
                      {file && (
                        <span className="name-file-upload">
                          {file}
                          <img
                            src={require("assets/images/icons/remove-file.png")}
                            className="icon-remove"
                            onClick={() => setFile(null)}
                            alt=""
                          />
                        </span>
                      )}
                      {isError === 4 && (
                        <span style={{ marginTop: "8px" }} className="error">
                          *Requied
                        </span>
                      )}
                    </div>
                    <p className="note-support">
                      Supported format: PDF, DOC, DOCX, JPG, PNG - Max size: 50KB
                      
                    </p>
                  </div>
                  <div className="upload-input">
                    <label className="btn-upload" htmlFor="upload">
                      Upload
                      <input
                        type="file"
                        className="input-file"
                        name="file_upload"
                        onChange={(e) => changeFile(e.target)}
                        accept="image/jpg, image/png, application/pdf, .doc, .docx, .zip, .rar"
                        id="upload"
                      />
                    </label>
                  </div>
                </div>
                <textarea
                  rows={5}
                  className="input-detail"
                  placeholder="Online Profile To Portfolio"
                  name="message"
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  onChange={handleChangeRecaptchaValue}
                  sitekey="6LdjL3cmAAAAAIyQEWTQ-C2VRk8sGyaQySOzf43Z"
                />
                <button type="submit" className="send-btn">
                  SEND US
                </button>
              </form>
            </div>
          </div>
        </div>
        {showPopup && (
          <Popup isShow={showPopup} onClose={() => setShowPopup(false)} center>
            <PopupSubmitSuccess
              onClose={() => setShowPopup(false)}
              content="You have submitted successfully"
            />
          </Popup>
        )}
      </div>
    )
  );
}

export default DetailJob;
