import classNames from "classnames";
import { useEffect, useState } from "react";
import "./Sec3.scss";

const listService = [
  {
    img: "internet.png",
    title: "WEB",
  },
  {
    img: "android.png",
    title: "ANDROID",
  },
  {
    img: "ios.png",
    title: "IOS",
  },
  {
    img: "iot.png",
    title: "IOT",
  },
  {
    img: "wearalabel.png",
    title: "WEARALABEL",
  },
  {
    img: "tv.png",
    title: "TV",
  },
];

function Sec3({ scrollPosition }) {
  const [animateFirst, setAnimateFirst] = useState(false);
  const [animateSecond, setAnimateSecond] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 800) {
      if (scrollPosition >= 2100) {
        setAnimateFirst(true);
        setAnimateSecond(true);
      }
    } else if (window.innerWidth <= 800 && window.innerWidth > 767) {
      if (scrollPosition >= 3200) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 3600) {
        setAnimateSecond(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 3600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 4000) {
        setAnimateSecond(true);
      }
    } else {
      if (scrollPosition >= 2400) {
        setAnimateFirst(true);
        setAnimateSecond(true);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="sec3">
      <div
        className={classNames("main-left", {
          animate: animateFirst,
        })}
      >
        <h1>Improve and Innovate with the Tech Trends</h1>
        <p className="content-left">
          Our team can assist you in transforming your businesses through latest
          tech capabilities tp stay ahead of the curve
        </p>
        <div className="list-checked">
          <div className="checked-item">
            <img
              src={require("assets/images/icons/checked.png")}
              className="icon-checked"
              alt=""
            />
            <p>Application Development</p>
          </div>
          <div className="checked-item">
            <img
              src={require("assets/images/icons/checked.png")}
              className="icon-checked"
              alt=""
            />
            <p>BI Consuliting and Implementation</p>
          </div>
          <div className="checked-item">
            <img
              src={require("assets/images/icons/checked.png")}
              className="icon-checked"
              alt=""
            />
            <p>Machine and Deep Learning</p>
          </div>
          <div className="checked-item">
            <img
              src={require("assets/images/icons/checked.png")}
              className="icon-checked"
              alt=""
            />
            <p>Data Quality Management</p>
          </div>
        </div>
      </div>
      <div
        className={classNames("main-right", {
          animate: animateSecond,
        })}
      >
        {listService.length > 0 &&
          listService.map((item, index) => (
            <div className="sec3-item" key={index}>
              <img
                alt=""
                src={require(`assets/images/icons/${item.img}`)}
                className="img-item"
              />
              <p className="title-item">{item.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Sec3;
