import "./PopupSubmitSuccess.scss";

function PopupSubmitSuccess(props) {
  const { onClose, content } = props;

  return (
    <div className="popup-submit-success">
      <div className="top-popup">
        <h4 className="title-popup">NOTE</h4>
        <button type="button" className="btn-close" onClick={onClose}>
          <img
            src={require("assets/images/icons/close_black.png")}
            className="icon-close"
            alt=""
          />
        </button>
      </div>
      <div className="content-popup">
        <img
          src={require("assets/images/icons/submit-success.png")}
          className="img-success"
          alt=""
        />
        <p className="content-popup-text">{content}</p>
      </div>
    </div>
  );
}

export default PopupSubmitSuccess;
